import React from 'react';
import ReactDOM from 'react-dom';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loc: "https://veelage.vbank.ng" };
    }

    componentWillMount(){
        window.location = this.state.loc;
    }

    render(){
        return (<section></section>);
    }
}

ReactDOM.render(
    <App />,
    document.querySelector('#root')
)